import { useGetCryptosQuery } from "services/crypto.api";
import { skipToken } from "@reduxjs/toolkit/dist/query";
import { useEffect, useState } from "react";

const useSearchCryptos = (simplified?: boolean) => {
  const [searchTerm, setSearchTerm] = useState("");
  const [cryptos, setCryptos] = useState<any>(null);

  const count = simplified ? 10 : 100;
  const { data: cryptosList, isFetching } = useGetCryptosQuery(
    count || skipToken
  );

  useEffect(() => {
    if (!isFetching) {
      const filteredData = cryptosList?.data?.coins.filter((coin: any) =>
        coin.name.toLowerCase().includes(searchTerm.toLowerCase())
      );

      setCryptos(filteredData);
    }
  }, [isFetching, cryptosList, searchTerm]);

  return [cryptos, setSearchTerm];
};

export default useSearchCryptos;
