import { skipToken } from "@reduxjs/toolkit/dist/query";
import { Descriptions, Statistic, Typography } from "antd";
import Loading from "components/Loader";
import millify from "millify";
import Currencies from "pages/Currencies";
import News from "pages/News";
import React from "react";
import { Link } from "react-router-dom";
import { useGetCryptosQuery } from "services/crypto.api";
import "styles/Home.css";
const { Title } = Typography;
type Props = {};

const Home: React.FC = (props: Props) => {
  const { data, isFetching } = useGetCryptosQuery(10 || skipToken);
  if (!data || isFetching) return <Loading />;
  const globalStats = data!.data!.stats;
  return (
    <>
      {globalStats && (
        <>
          <Title level={2} className="heading">
            General overview
          </Title>
          <Descriptions title="Global Crypto Stats" bordered>
            <Descriptions.Item label="Total Crypto">
              <Statistic value={globalStats.total} />
            </Descriptions.Item>
            <Descriptions.Item label="Total Exchanges">
              <Statistic value={millify(globalStats.totalExchanges)} />
            </Descriptions.Item>
            <Descriptions.Item label="Total Market Cap">
              <Statistic value={millify(globalStats.totalMarketCap)} />
            </Descriptions.Item>
            <Descriptions.Item label="Total 24h Volume">
              <Statistic value={millify(globalStats.total24hVolume)} />
            </Descriptions.Item>
            <Descriptions.Item label="Total Markets">
              <Statistic value={millify(globalStats.totalMarkets)} />
            </Descriptions.Item>
          </Descriptions>

          <div className="home-heading-container">
            <Title level={2} className={"home-title"}>
              Top 10 Crypto in the world
            </Title>
            <Title level={2} className={"show-more"}>
              <Link to={"/crypto"}>Show more</Link>
            </Title>
          </div>
          <Currencies simplified />
          <div className="home-heading-container">
            <Title level={2} className={"home-title"}>
              Latest crypto news
            </Title>
            <Title level={2} className={"show-more"}>
              <Link to={"/news"}>Show more</Link>
            </Title>
          </div>
          <News simplified />
        </>
      )}
    </>
  );
};

export default Home;
