import { configureStore } from "@reduxjs/toolkit";
import { coinRankingApi } from "services/coinranking.api";
import { cryptoApi } from "services/crypto.api";
import { newsApi } from "../services/news.api";

const store = configureStore({
  reducer: {
    [newsApi.reducerPath]: newsApi.reducer,
    [cryptoApi.reducerPath]: cryptoApi.reducer,
    [coinRankingApi.reducerPath]: coinRankingApi.reducer,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware().concat(cryptoApi.middleware),
});

export default store;
