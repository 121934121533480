import { Layout, Space, Typography } from "antd";
import { Content, Footer, Header } from "antd/lib/layout/layout";
import React from "react";
import { Outlet } from "react-router";
import { Link } from "react-router-dom";
import "styles/Layout.css";
import Navbar from "./Navbar";

type Props = {};

const AppLayout: React.FC = (props: Props) => {
  return (
    <Layout className="layout-container">
      <Header style={{ height: "100%" }}>
        <Navbar />
      </Header>
      <Content style={{ padding: "0 50px" }}>
        <div
          className="site-layout-content"
          style={{ height: "100%", minHeight: "100vh" }}
        >
          <Outlet />
        </div>
      </Content>

      <Footer style={{ textAlign: "center" }}>
        <Typography.Title level={4} style={{ textAlign: "center" }}>
          DeFinder <br />
          All rights reserved
        </Typography.Title>
        <Space>
          <Link to={"/"}>Home</Link>
          <Link to={"/exchanges"}>Exchanges</Link>
          <Link to={"/crypto"}>Currencies</Link>
          <Link to={"/news"}>News</Link>
        </Space>
      </Footer>
    </Layout>
  );
};

export default AppLayout;
