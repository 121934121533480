import { useEffect, useState } from "react";
import {
  useGetCryptoDetailsQuery,
  useGetCryptoHistoryQuery,
} from "services/crypto.api";
import getStats from "utils/details.params";

const useCryptoDetails = (coinId: string) => {
  const [cryptoDetails, setCryptoDetails] = useState<any>(null);
  const [coinHistory, setCryptoHistory] = useState<any>(null);
  const [stats, setStats] = useState<ReturnType<typeof getStats> | null>(null);
  const [timePeriod, setTimePeriod] = useState("7d");
  const {
    data,
    error: eDetails,
    isFetching: isFetchDetails,
  } = useGetCryptoDetailsQuery(coinId);
  const {
    data: cryptoHistory,
    error: eHistory,
    isFetching: isFetchHistory,
  } = useGetCryptoHistoryQuery({
    coinId,
    timePeriod,
  });
  const isFetching = isFetchDetails || isFetchHistory;

  useEffect(() => {
    !isFetchDetails && data && setCryptoDetails(data.data.coin);
  }, [isFetchDetails, data, stats, cryptoDetails]);
  useEffect(() => {
    !isFetchDetails && cryptoDetails && setStats(getStats(cryptoDetails));
  }, [isFetchDetails, cryptoDetails]);
  useEffect(() => {
    !isFetchHistory && setCryptoHistory(cryptoHistory);
  }, [timePeriod, cryptoHistory, isFetchHistory]);

  return {
    coinHistory,
    cryptoDetails,
    stats,
    isFetching,
    error: eDetails && eHistory,
    setTimePeriod,
  };
};

export default useCryptoDetails;
