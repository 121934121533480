import Currencies from "pages/Currencies";
import Exchanges from "pages/Exchanges";
import News from "pages/News";
import React from "react";
import {
  BrowserRouter as Router,
  Navigate,
  Route,
  Routes,
} from "react-router-dom";
import "./App.css";
import Home from "./pages/Home";
import AppLayout from "./components/Layout";
import CurrencyDetail from "pages/CurrencyDetail";

type Props = {};

const App: React.FC = (props: Props) => {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<AppLayout />}>
          <Route index element={<Home />} />
          <Route path="cryptos" element={<Currencies />} />
          <Route path="crypto">
            <Route index element={<Navigate replace to={"/cryptos"} />} />
            <Route path=":coinId" element={<CurrencyDetail />} />
          </Route>
          <Route path="exchanges" element={<Exchanges />} />
          <Route path="news" element={<News />} />
        </Route>
      </Routes>
    </Router>
  );
};

export default App;
