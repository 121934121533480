import { Col, Card, Avatar, Typography } from "antd";
import moment, { unitOfTime } from "moment";
import React from "react";
import "styles/News.css";

const demoImage =
  "https://images.pexels.com/photos/4808267/pexels-photo-4808267.jpeg?auto=compress&cs=tinysrgb&dpr=1&w=500";

type IArticleProps = {
  article: any;
};

const { Title, Text } = Typography;

const NewsArticle: React.FC<IArticleProps> = ({ article }) => {
  return (
    <Col xs={24} sm={12} lg={8}>
      <Card hoverable className="news-card">
        <a href={article.url} target="_blank" rel="noreferrer">
          <div className="news-image-container">
            <Title className="news-title" level={4}>
              {article.name}
            </Title>
            <img
              style={{
                maxWidth: "100px",
                width: "auto",
                objectFit: "scale-down",
              }}
              src={article?.image?.thumbnail.contentUrl ?? demoImage}
              alt="Crypto News"
            />
          </div>
          <p>
            {article.description > 100
              ? `${article.description.substring(0, 100)}...`
              : article.description}
          </p>
          <div className="provider-container">
            <div>
              <Avatar
                src={
                  article.provider[0]?.image?.thumbnail.contentUrl ?? demoImage
                }
                alt={"News Provider Logo"}
              />
              <Text className="provider-name">{article.provider[0]?.name}</Text>
            </div>
            <Text>
              {moment(article.datePublished)
                .startOf("ss" as unitOfTime.StartOf)
                .fromNow()}
            </Text>
          </div>
        </a>
      </Card>
    </Col>
  );
};

export default NewsArticle;
