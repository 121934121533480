import {
  BulbOutlined,
  FundOutlined,
  HomeOutlined,
  MenuOutlined,
  MoneyCollectOutlined,
} from "@ant-design/icons";
import { Avatar, Col, Menu, Row, Space } from "antd";
import React from "react";
import { Link } from "react-router-dom";
import "styles/Navbar.css";
import icon from "../images/currency.png";
type Props = {};

const Navbar: React.FC = (props: Props) => {
  return (
    <Row>
      <Col span={8}>
        <Space size={"middle"} align="center" style={{ height: "100%" }}>
          <Avatar
            size={{ xs: 24, sm: 32, md: 40, lg: 56, xl: 56, xxl: 56 }}
            src={icon}
          />

          <Link to="/" style={{ fontWeight: "bold", fontSize: "2rem" }}>
            De
            <span style={{ color: "#f6f6f6" }}>Fi</span>nder
          </Link>
        </Space>
      </Col>

      <Col span={8} offset={8}>
        <Menu
          theme="dark"
          mode="horizontal"
          overflowedIndicator={<MenuOutlined />}
          defaultSelectedKeys={["1"]}
          style={{ height: "100%" }}
        >
          <Menu.Item key={"home"} icon={<HomeOutlined />}>
            <Link to="/">Home</Link>
          </Menu.Item>
          <Menu.Item key={"crypto"} icon={<FundOutlined />}>
            <Link to="/crypto">Cryptocurrencies</Link>
          </Menu.Item>
          <Menu.Item key={"exchanges"} icon={<MoneyCollectOutlined />}>
            <Link to="/exchanges">Exchanges</Link>
          </Menu.Item>
          <Menu.Item key={"news"} icon={<BulbOutlined />}>
            <Link to="/news">News</Link>
          </Menu.Item>
        </Menu>
      </Col>
    </Row>
  );
};

export default Navbar;
