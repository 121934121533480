import { Col, Row, Typography } from "antd";
import React from "react";
import { Line } from "react-chartjs-2";
import { Chart, registerables } from "chart.js";
import moment from "moment";
import "styles/LineChart.css";
Chart.register(...registerables);
type Props = {
  coinHistory?: any;
  currentPrice?: any;
  coinName?: any;
};

const Title = Typography.Title;

const LineChart = ({ coinHistory, currentPrice, coinName }: Props) => {
  const coinPrice: string[] = [];
  const coinTimestamp: string[] = [];

  coinHistory?.data?.history.forEach((historyItem: any) => {
    coinPrice.unshift(historyItem.price);
    coinTimestamp.unshift(
      moment.unix(historyItem.timestamp).format("YYYY-MM-DD")
    );
  });

  const data = {
    labels: coinTimestamp,
    datasets: [
      {
        label: "Price in USD",
        data: coinPrice,
        fill: false,
        backgroundColor: "#0071bd",
        borderColor: "#0071bd",
      },
    ],
  };

  const options = {
    responsive: true,
    scales: {
      y: {
        beginAtZero: true,
        grid: {
          color: "#6fd5ee",
          lineWidth: 0.1,
        },
      },
      x: {
        grid: {
          color: "#6fd5ee",
          lineWidth: 0.1,
        },
      },
    },
  };

  return (
    <>
      <Row className="chart-header">
        <Typography.Title level={2} className={"chart-title"}>
          {coinName} Price Chart
        </Typography.Title>
        <Col className="price-container">
          <Title level={5} className="price-change">
            {coinHistory?.data?.change}%
          </Title>
          <Title level={5} className="current-price">
            Current {coinName} Price: ${currentPrice}
          </Title>
        </Col>
      </Row>
      <Line data={data} options={options} />
    </>
  );
};

export default LineChart;
