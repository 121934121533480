import {
  createApi,
  FetchArgs,
  fetchBaseQuery,
} from "@reduxjs/toolkit/query/react";

type TRequestHeaders = FetchArgs["headers"];
const newsHeaders: TRequestHeaders = {
  "x-rapidapi-host": "bing-news-search1.p.rapidapi.com",
  "x-rapidapi-key": "98d2960090msh550514370092d5ap105fb8jsnd05c84b443a4",
  "x-bingapis-sdk": "true",
};

const baseUrl = "https://bing-news-search1.p.rapidapi.com";

const createRequest = (url: string): FetchArgs => ({
  url,
  headers: newsHeaders,
});

interface INewsCategoryRequest {
  newsCategory: string;
  count: number;
}
export const newsApi = createApi({
  reducerPath: "newsApi",
  baseQuery: fetchBaseQuery({ baseUrl }),
  endpoints: (builder) => ({
    getNews: builder.query({
      query: ({ newsCategory, count }: INewsCategoryRequest) =>
        createRequest(
          `/news/search?q=${newsCategory}&count=${count}&safeSearch=Off&textFormat=Raw&freshness=Day`
        ),
    }),
  }),
});

export const { useGetNewsQuery } = newsApi;
