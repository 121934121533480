import { skipToken } from "@reduxjs/toolkit/dist/query";
import { useEffect, useState } from "react";
import { useGetNewsQuery } from "services/news.api";

const useCryptoNews = (simplified?: boolean) => {
  //   const [searchTerm, setSearchTerm] = useState("");
  const [cryptoNews, setCryptoNews] = useState<any>(null);
  const [newsCategory, setNewsCategory] = useState("Cryptocurrency");

  const count = simplified ? 6 : 12;
  const {
    data: news,
    error,
    isFetching,
  } = useGetNewsQuery(
    {
      newsCategory,
      count,
    } || skipToken
  );

  useEffect(() => {
    setCryptoNews(news);
  }, [cryptoNews, isFetching, news]);

  return { cryptoNews, error, isFetching, setNewsCategory };
};

export default useCryptoNews;
