// coinrankinge3108991c987e22d85cdbee458aa3c5afd40b517142a0f5e
import {
  createApi,
  FetchArgs,
  fetchBaseQuery,
} from "@reduxjs/toolkit/query/react";

type TRequestHeaders = FetchArgs["headers"];
const coinRankingHeaders: TRequestHeaders = {
  "x-access-token":
    "coinrankinge3108991c987e22d85cdbee458aa3c5afd40b517142a0f5e",
};

const baseUrl = "https://api.coinranking.com/v2";

const createRequest = (url: string): FetchArgs => ({
  url,
  headers: coinRankingHeaders,
});

interface ICryptoHistoryParams {
  coinId: string;
  timePeriod: string;
}
export const coinRankingApi = createApi({
  reducerPath: "coinRankingApi",
  baseQuery: fetchBaseQuery({ baseUrl }),
  endpoints: (builder) => ({
    getCoins: builder.query({
      query: (count: number) =>
        createRequest(
          `/coins?referenceCurrencyUuid=yhjMzLPhuIDl&timePeriod=24h&tiers=1&orderBy=marketCap&orderDirection=desc&limit=${count}&offset=0`
        ),
    }),
    getCoinExchanges: builder.query({
      query: () => createRequest(`/coin/Qwsogvtv82FCd/exchanges`),
    }),
    getCoinDetails: builder.query({
      query: (coinId: string) => createRequest(`/coin/${coinId}`),
    }),
    getCoinHistory: builder.query({
      query: ({ coinId, timePeriod }: ICryptoHistoryParams) =>
        createRequest(`/coin/${coinId}/history?timePeriod=${timePeriod}`),
    }),
  }),
});

export const {
  useGetCoinsQuery,
  useGetCoinExchangesQuery,
  useGetCoinDetailsQuery,
  useGetCoinHistoryQuery,
} = coinRankingApi;
