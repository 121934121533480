import millify from "millify";
import {
  MoneyCollectOutlined,
  DollarCircleOutlined,
  FundOutlined,
  ExclamationCircleOutlined,
  StopOutlined,
  TrophyOutlined,
  CheckOutlined,
  NumberOutlined,
  ThunderboltOutlined,
} from "@ant-design/icons";

const time = ["3h", "24h", "7d", "30d", "1y", "3m", "3y", "5y"];

export interface ICurrencyStats {
  title: string;
  value: string;
  icon: any;
}
const stats: (details: any) => ICurrencyStats[] = (details) => [
  {
    title: "Price to USD",
    value: `$ ${details?.price && millify(details?.price)}`,
    icon: <DollarCircleOutlined />,
  },
  { title: "Rank", value: details?.rank, icon: <NumberOutlined /> },
  {
    title: "24h Volume",
    value: `$ ${details["24hVolume"] && millify(details["24hVolume"])}`,
    icon: <ThunderboltOutlined />,
  },
  {
    title: "Market Cap",
    value: `$ ${details?.marketCap && millify(details?.marketCap)}`,
    icon: <DollarCircleOutlined />,
  },
  {
    title: "All-time-high(daily avg.)",
    value: `$ ${
      details?.allTimeHigh?.price && millify(details?.allTimeHigh?.price)
    }`,
    icon: <TrophyOutlined />,
  },
];

const genericStats: (details: any) => ICurrencyStats[] = (details) => [
  {
    title: "Number Of Markets",
    value: details?.numberOfMarkets,
    icon: <FundOutlined />,
  },
  {
    title: "Number Of Exchanges",
    value: details?.numberOfExchanges,
    icon: <MoneyCollectOutlined />,
  },
  {
    title: "Aprroved Supply",
    value: details?.supply?.confirmed ? <CheckOutlined /> : <StopOutlined />,
    icon: <ExclamationCircleOutlined />,
  },
  {
    title: "Total Supply",
    value: `$ ${details?.supply?.total && millify(details?.supply?.total)}`,
    icon: <ExclamationCircleOutlined />,
  },
  {
    title: "Circulating Supply",
    value: `$ ${
      details?.supply?.circulating && millify(details?.supply?.circulating)
    }`,
    icon: <ExclamationCircleOutlined />,
  },
];

const getStats = (details: any) => ({
  time,
  stats: stats(details),
  genericStats: genericStats(details),
});

export default getStats;
