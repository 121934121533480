import { Avatar, Space, Table } from "antd";
import Text from "antd/lib/typography/Text";
import { Breakpoint } from "antd/lib/_util/responsiveObserve";
import Loading from "components/Loader";
import useCoinExchanges from "hooks/useCoinExchanges";
import useScreenSize from "hooks/useScreenSize";
import millify from "millify";
import React, { useEffect, useState } from "react";

type Props = {};

const columns = [
  { title: "Rank", dataIndex: "rank", key: "rank" },
  {
    title: "Exchange",
    dataIndex: "name",
    key: "name",
    render: (name: string, record: any) => (
      <Space align="center" size={"middle"}>
        <Avatar className="exchange-image" src={record.iconUrl} />
        <Text>
          <strong>{name}</strong>
        </Text>
      </Space>
    ),
  },

  {
    title: "24h Trade Volumne",
    dataIndex: "24hVolume",
    key: "24hVolume",
    responsive: ["md"] as Breakpoint[],
    render: (text: any, record: any) => <Text>${millify(text)}</Text>,
  },
  {
    title: "Markets",
    dataIndex: "numberOfMarkets",
    key: "numberOfMarkets",
    responsive: ["lg"] as Breakpoint[],
    render: (numberOfMarkets: any) => <Text>{millify(numberOfMarkets)}</Text>,
  },
  {
    title: "BTC Price",
    dataIndex: "btcPrice",
    key: "btcPrice",
    responsive: ["lg"] as Breakpoint[],
    render: (btcPrice: any) => <Text>{btcPrice}</Text>,
  },
];

const expandableConfig = {
  expandedRowRender: (record: any) => (
    <Space align="center" size={"middle"} wrap>
      <span>
        <Text>
          <strong>24h Volume:</strong>
        </Text>
        ${millify(record["24hVolume"])}
      </span>
      <span>
        <Text>
          <strong>Markets:</strong>
        </Text>
        {millify(record.numberOfMarkets)}
      </span>
      <span>
        <Text>
          <strong>BTC Price:</strong>
        </Text>
        {record.btcPrice}
      </span>
    </Space>
  ),
};
const Exchanges = (props: Props) => {
  const { screenSize } = useScreenSize();
  const { exchangesList, isFetching } = useCoinExchanges();
  const [expandable, setExpandable] = useState<
    typeof expandableConfig | undefined
  >(undefined);
  useEffect(() => {
    if (screenSize! <= 800) {
      setExpandable(expandableConfig);
    } else {
      setExpandable(undefined);
    }
  }, [screenSize]);

  if (!exchangesList || isFetching) return <Loading />;
  return (
    <Table
      rowKey={"uuid"}
      columns={columns}
      expandable={expandable}
      dataSource={exchangesList}
    />
  );
};

export default Exchanges;
