import { useEffect, useState } from "react";
import { useGetExchangesQuery } from "services/crypto.api";

const useCoinExchanges = () => {
  const [exchangesList, setExchangesList] = useState<Array<any> | null>(null);
  const { data, error, isFetching } = useGetExchangesQuery(null);

  useEffect(() => {
    !isFetching && data && setExchangesList(data?.data?.exchanges);
  }, [isFetching, data]);

  return { exchangesList, error, isFetching };
};

export default useCoinExchanges;
