import { Col, Typography } from "antd";
import React from "react";
import { ICurrencyStats } from "utils/details.params";
import "styles/StatsWidget.css";
const { Title, Text } = Typography;

type Props = {
  stats?: ICurrencyStats[];
  title: string;
  desc: string;
};

const StatsWidget = ({ stats, title, desc }: Props) => {
  return (
    <Col xs={{ span: 24 }} lg={{ span: 12 }}>
      <Col className="coin-value-statistics">
        <Col className="coin-value-statistics-heading">
          <Title level={3} className="coin-details-heading">
            {title}
          </Title>

          <p>{desc}</p>
          {stats?.map(({ icon, title, value }, index: number) => (
            <Col key={index} className="coin-stats">
              <Col className="coin-stats-name">
                <Text>{icon}</Text>
                <Text>{title}</Text>
              </Col>
              <Text className="stats">{value}</Text>
            </Col>
          ))}
        </Col>
      </Col>
    </Col>
  );
};

export default StatsWidget;
