import { Spin } from "antd";
import React from "react";
import "styles/Loader.css";
type Props = {};

const Loading = (props: Props) => {
  return (
    <div className="loader">
      <Spin />
    </div>
  );
};

export default Loading;
