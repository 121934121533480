import { Col, Card } from "antd";
import millify from "millify";
import React from "react";
import { Link } from "react-router-dom";
import "styles/CoinWidget.css";

type Props = { currency: any };

const CoinWidget: React.FC<Props> = ({ currency }) => {
  return (
    <Col xs={24} sm={12} lg={6} className="crypto-card">
      <Link to={`/crypto/${currency.uuid}`}>
        <Card
          title={`${currency.rank}. ${currency.name}`}
          extra={<img className="crypto-image" src={currency.iconUrl} alt="" />}
          hoverable
        >
          <p>Price: {millify(currency.price)}</p>
          <p>Market Cap: {millify(currency.marketCap)}</p>
          <p>Daily Change: {millify(currency.price)}%</p>
        </Card>
      </Link>
    </Col>
  );
};

export default CoinWidget;
