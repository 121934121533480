import { Input, Row } from "antd";
import CoinWidget from "components/CoinWidget";
import useSearchCryptos from "hooks/useSearchCryptos";
import React, { useMemo } from "react";
import "styles/Currencies.css";

interface ICurrenciesProps {
  simplified?: boolean;
  currencyId?: string;
}

const Currencies: React.FC<ICurrenciesProps> = ({ simplified, currencyId }) => {
  const [cryptos, setSearchTerm] = useSearchCryptos(simplified);

  const cryptoList: JSX.Element[] = useMemo(
    () =>
      cryptos?.map((currency: any) => (
        <CoinWidget key={currency.uuid} currency={currency} />
      )),
    [cryptos]
  );

  return (
    <>
      {!simplified && (
        <div className="search-crypto">
          <Input
            placeholder="Search Cryptocurrency"
            onChange={(e) => setSearchTerm(e.target.value)}
          />
        </div>
      )}
      {cryptos && (
        <>
          <Row gutter={[32, 32]} className="crypto-card-container">
            {cryptoList}
          </Row>
        </>
      )}
    </>
  );
};

export default Currencies;
