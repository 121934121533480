import { Col, Row, Select } from "antd";
import useCryptoNews from "hooks/useCryptoNews";
import React from "react";
import { useGetCryptosQuery } from "services/crypto.api";
import Loading from "../components/Loader";
import NewsArticle from "../components/NewsArticle";

interface INewsProps {
  simplified?: boolean;
}
const { Option } = Select;

const News: React.FC<INewsProps> = ({ simplified }) => {
  const { cryptoNews, isFetching, setNewsCategory } = useCryptoNews(simplified);
  const { data } = useGetCryptosQuery(100);
  if (!cryptoNews || isFetching) return <Loading />;
  return (
    <Row gutter={[24, 24]}>
      {!simplified && (
        <Col span={24}>
          <Select
            showSearch
            className="select-news"
            placeholder="Select a Crypto"
            optionFilterProp="children"
            onChange={(value) => setNewsCategory(value)}
            filterOption={(input, option) => {
              // option!.children!.filter(
              //   (opt) => opt!.value!.toLowerCase() === input!.toLowerCase()
              // )!.length >= 0
              console.log(option);
              return (
                option?.props?.children
                  ?.toLowerCase()
                  .indexOf(input.toLowerCase()) >= 0
              );
            }}
          >
            <Option value="Cryptocurrency">Cryptocurrency</Option>
            {data?.data?.coins.map((coin: any, idx: number) => (
              <Option key={coin.name} value={coin.name}>
                {coin.name}
              </Option>
            ))}
          </Select>
        </Col>
      )}
      {cryptoNews && !isFetching && (
        <Row>
          {cryptoNews.value.map((article: any) => (
            <NewsArticle article={article} key={article.url} />
          ))}
        </Row>
      )}
    </Row>
  );
};

export default News;
