import { Col, Row, Select, Typography } from "antd";
import Loading from "components/Loader";
import StatsWidget from "components/StatsWidget";
import useCryptoDetails from "hooks/useCryptoDetails";
import React from "react";
import { useParams } from "react-router";
import HTMLReactParser from "html-react-parser";
import LineChart from "components/LineChart";
import millify from "millify";
import "styles/CurrencyDetail.css";
const { Title } = Typography;
const { Option } = Select;

type Props = {};

const CurrencyDetail = (props: Props) => {
  const { coinId } = useParams();
  const { coinHistory, cryptoDetails, stats, isFetching, setTimePeriod } =
    useCryptoDetails(coinId!);
  if (isFetching || !cryptoDetails || !stats || !coinHistory)
    return <Loading />;
  return (
    <Col className="coin-detail-container">
      <Col className="coin-heading-container">
        <Title level={2} className={"coin-name"}>
          {cryptoDetails?.name} ({cryptoDetails?.symbol}) Price
        </Title>
        <p>
          {cryptoDetails?.name} live price in US dollars. View value statistics,
          market cap and supply.
        </p>
      </Col>
      <Select
        defaultValue={"7d"}
        className="select-timeperiod"
        placeholder="Select Time Period"
        onChange={(value) => setTimePeriod(value)}
      >
        {stats?.time.map((date) => (
          <Option key={date}>{date}</Option>
        ))}
      </Select>
      <LineChart
        coinHistory={coinHistory}
        currentPrice={millify(cryptoDetails?.price)}
        coinName={cryptoDetails?.name}
      />
      <Row gutter={[32, 32]}>
        <StatsWidget
          stats={stats?.stats}
          title={`${cryptoDetails?.name} Value statistics`}
          desc={`An overview showing the stats of ${cryptoDetails?.name}`}
        />
        <StatsWidget
          stats={stats?.genericStats}
          title={"Other Statistics"}
          desc={"An overview showing the stats of all cryptocurrencies"}
        />
      </Row>
      <Col className="coin-desk-link">
        <Row className="coin-desc">
          <Title level={3} className="coin-details-heading">
            What is {cryptoDetails?.name}
          </Title>
          {HTMLReactParser(cryptoDetails?.description)}
        </Row>
        <Col className="coin-links">
          <Title level={3} className="coin-details-heading">
            {cryptoDetails?.name} Links
          </Title>
          {cryptoDetails?.links.map((link: any, index: number) => (
            <Row key={link.name.concat(link.type)} className="coin-link">
              <Title level={5} className="link-name">
                {link.type}
              </Title>
              <a href="link.url" target={"_blank"}>
                {link.name}
              </a>
            </Row>
          ))}
        </Col>
      </Col>
    </Col>
  );
};

export default CurrencyDetail;
